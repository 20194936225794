<template>
  <div class="Balance">
    <div class="banlance-fixed">
      <van-nav-bar
        title="Balance Record"
        class="record_nav"
        :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
      >
        <i
          slot="left"
          class="iconfont icon-jiantou1"
          @click="$router.back()"
          :color="!$isLinkGame ? '#ffd400' : '#fff'"
        ></i>
        <template #title>
          <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'"
            >Balance Record</span
          >
        </template>
      </van-nav-bar>

      <van-tabs
        v-model="active"
        swipeable
        line-width="15px"
        @click="ClickStatus"
      >
        <van-tab
          v-for="(item, index) in lists"
          :title="item"
          :key="index"
        ></van-tab>
      </van-tabs>
    </div>
    <!-- 列表 -->
    <div class="balance-box">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :success-text="refreshSuccessText"
        loosing-text="Phát hành để làm mới..."
        loading-text="Loading..."
        pulling-text="Thả xuống để làm mới ..."
      >
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          finished-text="kết nối internet"
          loading-text="Đang tải..."
        >
          <van-cell v-for="(item, index) in list" :key="index">
            <div slot="title" class="list-item-title">
              <van-notice-bar
                :scrollable="item.title.startsWith('คำสั่ง') ? true : false"
                color="#242424"
                background="#fff"
                delay="0.5"
                :text="item.title"
              />
              <span class="list-item-indate">{{
                (item.indate * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
              }}</span>
            </div>
            <div slot="default" class="list-item-right">
              <div
                class="list-item-money list-item-money-green"
                v-if="item.setup < 0"
              >
                {{ item.setup }}
              </div>
              <div class="list-item-money" v-else>+{{ item.setup }}</div>
              <span class="list-item-after">Balance: {{ item.after }}</span>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>

    <!-- language-ch 没有任何记录 -->
    <div v-if="listsNo" class="nolist">There's no record of anything</div>
  </div>
</template>

<script>
import { getBalanceRecord } from '@/api/lottery.js'
export default {
  name: 'Balance',
  components: {},
  data() {
    return {
      active: 0,
      lists: ['ALL', 'Income', 'Expenditure'],
      list: [], // 获取到的所有数据
      loading: false,
      finished: false,
      listsNo: false,
      refreshing: false,
      refreshSuccessText: '', // 下拉刷新成功时显示的文字
      pages: 1,
    }
  },
  mounted() {},
  created() {
    this.loadData()
  },
  methods: {
    onLoad() {
      this.loadData()
    },
    async onRefresh() {
      const {
        data: { data },
      } = await getBalanceRecord({
        type: 'money',
        setup: this.active,
        page: this.pages,
        subtype: '',
      })
      try {
        // 2、将数据追加到list 列表的最上面
        this.list.unshift(...data.results)
        // 3、下拉结束之后就将下拉状态关闭
        this.refreshing = false
        // 4、下拉成功时显示提示文字
        this.refreshSuccessText = 'achieve new success'
      } catch (error) {
        // 如果下拉失败，则关闭下拉状态，显示下拉失败的消息
        this.refreshing = false
        this.refreshSuccessText = 'Update failed'
      }
    },

    // 点击每一หนึ่งสถานที่tabs 标签时触发的事件
    ClickStatus() {
      this.loadData()
    },

    async loadData() {
      this.loading = true
      this.listsNo = false
      console.log(this.active)
      const {
        data: { ret, data, msg },
      } = await getBalanceRecord({
        type: 'money',
        setup: this.active,
        page: this.pages,
        subtype: '0',
      })
      try {
        console.log(data)
        ret === 1 ? this.list.push(...data.lists) : this.$toast(msg)
        this.loading = false
        if (data.lists.length > 0) {
          // 更新获取下一页数据的时间戳
          this.pages++
        } else {
          // 已经没有更多数据了，就将finished 的值设置为true，让loading不再加载
          this.finished = true
        }
      } catch (error) {
        this.Listloading = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.Balance {
  font-family: 'PingFang-Regular';
  .banlance-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    .record_nav {
      /deep/ .van-nav-bar__content {
        // background-color: #ff0000;
      }
      /deep/ .van-nav-bar__title {
        font-family: 'PingFang-Regular';
        // color: #fff;
      }
      .iconfont {
        font-size: 44px;
        // color: #fff;
      }
    }
  }
  .balance-box {
    margin-top: 180px;
    .van-cell {
      padding: 20px;
      /deep/ .van-cell__title {
        flex: 1.3;
      }
      /deep/ .van-cell__value {
        flex: 0.7;
      }
      .list-item-title {
        .van-notice-bar {
          padding-left: 4px;
          /deep/ .van-notice-bar__content {
            font-size: 26px;
            color: #242424;
          }
        }
        .list-item-indate {
          font-size: 23px;
          color: #888;
        }
      }
      .list-item-right {
        margin-top: 20px;
      }
      .list-item-money,
      .list-item-money-green {
        font-size: 34px;
        color: #dc1323;
      }
      .list-item-after {
        font-size: 18px;
      }
      .list-item-money-green {
        color: #1bb019;
      }
    }
  }

  .nolist {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 24px;
    color: #999999;
  }

  .list {
    margin: 50px 0;
    text-align: center;
    font-size: 28px;
  }
}
</style>
